'use client';

import { useEffect } from 'react';
import Typography from '@sympla/sympla-components/Typography';
import { ErrorContainer } from './Error.css';

type ErrorProps = {
    error?: Error & { digest?: string };
    title: string;
    message: string;
    caption: string;
    image: React.ReactNode;
};

export default function Error({
  error, title, message, caption, image,
}: ErrorProps) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <div className={ErrorContainer}>
      <div>
        <Typography variant="1" type="large" component="h1">{title}</Typography>
        <br />
        <Typography
          type="medium"
          variant="2"
          component="h2"
        >
          {message}
        </Typography>
        <Typography type="small">{caption}</Typography>
      </div>
      <div>
        {image}
      </div>
    </div>
  );
}
